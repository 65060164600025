import toCurrencyInput from "./toCurrencyInput";

const parseQuantity = (defaultValue, input, form, unitOfMeasurement, symbol = false) => {
    let value = (input !== 'undefined' && input || defaultValue)?.toString()
    if (value === undefined) {
        return;
    }
    const parsedUnitOfMeasurement = unitOfMeasurement || form.getFieldValue('unit_of_measurement');
    switch (parsedUnitOfMeasurement) {
        case 'unit':
            // Return only the integer part
            return Math.floor(value);
        case 'centimeter':
            // Return the value with two decimal places
            return toCurrencyInput(value, 0, { decimals: 2 }) + (symbol ? ' m' : '');
        case 'kilo':
            return toCurrencyInput(value, 0, { decimals: 3 }) + (symbol ? 'L' : '');
        case 'liters':
            // Return the value with three decimal places
            return toCurrencyInput(value, 0, { decimals: 3 }) + (symbol ? ' kg' : '');
        default:
            // If the unit of measurement is not recognized, return the original value
            return value;
    }
}
const reverseQuantity = (quantity, unit) => {
    //if (typeof (quantity) === 'number') return quantity;
    const sanitizedQuantity = parseInt(`${quantity}`.replace(/\D/g, ''));
    if (["liters", "kilo", "gram"].includes(unit)) return (sanitizedQuantity / 1000).toFixed(3);
    if (unit === "centimeter") return (sanitizedQuantity / 100).toFixed(2);
    return sanitizedQuantity;
}

const setNativeValue = (id, value) => {
    let element = document.getElementById(id);
    if (!element) return;
    let lastValue = element.value;
    element.value = value;
    let event = new Event("input", { target: element, bubbles: true });
    // React 15
    event.simulated = true;
    // React 16
    let tracker = element._valueTracker;
    if (tracker) {
        tracker.setValue(lastValue);
    }
    element.dispatchEvent(event);
}
export { parseQuantity, reverseQuantity, setNativeValue }