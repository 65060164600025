const Button = ({ children, onClick, style }) => {
  return (
    <div
      onClick={() => onClick()}
      style={{
        background: "#fff",
        width: 200,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        borderRadius: 30,
        cursor: "pointer",
        ...style,
      }}
    >
      <button
        className="bold"
        style={{
          color: "black",
          fontSize: 20,
          textAlign: "center",
          display: "flex",
          alignSelf: "center",
          border: "none",
          background: "none",
          cursor: "pointer",
          padding: 0,
        }}
      >
        {children}
      </button>
    </div>
  );
};
export default Button;
