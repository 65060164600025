import React, { useContext, useState } from "react";
import {
  AccountCircleOutlined,
  PeopleAltOutlined,
  Search as SearchIcon,
  WorkHistoryOutlined,
} from "@mui/icons-material";
import { AppBar, Toolbar } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { DarkModeSwitch } from "react-toggle-dark-mode"; // Assuming this is the correct import
import { ThemeContext } from "themes/themeContext";
import Icon from "assets/images/Icon.svg";
import {
  PieChartOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  PlusOutlined,
  AppstoreAddOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";

const getItems = (isExpanded) => [
  {
    label: null,
    key: "0",
    icon: <img alt="icon" height={40} src={Icon} />,
    disabled: true,
    style: {
      cursor: "default",
      marginBottom: 20,
      display: "flex",
      flexDirection: "column",
      paddingInline: 0,
      marginInline: 0,
      alignItems: "center",
      justifyContent: "center",
    },
  },
  {
    label: "Dashboard",
    key: "app",
    icon: <PieChartOutlined />,
  },
  {
    label: "Produtos",
    key: "app/products",
    icon: <ShoppingOutlined />,
  },
  {
    label: "Clientes",
    key: "app/customers",
    icon: <PeopleAltOutlined />,
  },
  {
    label: "Vendedores",
    key: "app/employees",
    icon: <AccountCircleOutlined />,
  },
  {
    label: "Histórico de vendas",
    key: "app/sales-history",
    icon: <WorkHistoryOutlined />,
  },
  {
    label: "Cadastros",
    key: "app/cadastros",
    icon: <PlusOutlined />,
    type: "item",
    children: [
      {
        label: "Novo produto",
        key: "product",
        icon: <AppstoreAddOutlined />,
        type: "item",
      },
      {
        label: "Novo cliente",
        key: "customer",
        icon: <UserAddOutlined />,
        type: "item",
      },
      {
        label: "Novo vendedor",
        key: "employee",
        icon: <UsergroupAddOutlined />,
        type: "item",
      },
    ],
  },
  {
    label: !isExpanded ? "Expandir" : "Retrair",
    key: "expand",
    icon: !isExpanded ? <DoubleRightOutlined /> : <DoubleLeftOutlined />,
    style: {
      cursor: "pointer",
      position: "absolute",
      bottom: 20,
    },
  },
];

const Layout = ({ children }) => {
  const { toggleTheme, theme } = useContext(ThemeContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const { pathname } = useLocation();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.paper, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: isDarkMode ? "#fff" : "#0f0f0f",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: isDarkMode ? "#fff" : "#000",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const handleClick = (key) => {
    if (key[0] !== "expand") {
      navigate(`/${key.reverse().join("/")}`);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        overflow: "hidden",
      }}
    >
      <Menu
        style={{
          minHeight: "100%",
          position: "fixed",
          maxWidth: "20%",
          height: "100%",
          zIndex: 1,
          backgroundColor: isDarkMode ? "#17241f" : "#d1f6eb",
          paddingTop: 20,
          boxShadow: `-2px 2px 7px 1px ${!isDarkMode ? "#17241f" : "#70cfb2"}`,
        }}
        selectedKeys={[pathname.slice(1)]}
        mode="inline"
        theme={isDarkMode ? "dark" : "light"}
        inlineCollapsed={!isExpanded}
        items={getItems(isExpanded)}
        onClick={({ keyPath }) => handleClick(keyPath)}
      ></Menu>
      <div
        onClick={() => setIsExpanded(false)}
        style={{
          width: isExpanded ? "80%" : "100%",
          marginLeft: isExpanded ? "20%" : 80,
          transition: "all 0.1s ease-in-out",
        }}
      >
        <AppBar position="static">
          <Toolbar>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Pesquisar..."
                inputProps={{ "aria-label": "pesquisa" }}
              />
            </Search>
            <div style={{ position: "absolute", right: 20 }}>
              <DarkModeSwitch
                sunColor="#edfbf7"
                moonColor="#a127d8"
                checked={isDarkMode}
                onChange={toggleTheme}
                size={25}
              />
            </div>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: 30 }}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
