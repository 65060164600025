export default function applyCPFMaskOnTyping(input, fallback) {
    const baseCpf = input || fallback
    if (!baseCpf) return
    if (baseCpf.length > 14) return baseCpf.substring(0, 14)
    let cpf = baseCpf.replace(/\D/g, '')

    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2')

    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2')

    return cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
}
