import api from "api";
import { FilterAltOff } from "@mui/icons-material";
import { Button, Form, Input, Modal, Select, Slider } from "antd";
import toBRL from "helpers/toBRL";
import { useState } from "react";
import { useQuery } from "react-query";
const FilterModal = ({ visible, onClose, clear, onFilter }) => {
    const [form] = Form.useForm();
    const [values, setValues] = useState(initialValues);
    const getCategories = async () => {
        const companyId = sessionStorage.getItem("companyId");
        const { data: response } = await api.get(`/categories?company_id=${companyId}`);
        return response.data;

    }
    const { data: categories } = useQuery("categories", getCategories, { initialData: [], enabled: visible });
    const clearFilter = () => {
        setValues(initialValues);
        form.resetFields();
        clear();
        onClose();
    };
    return (
        <Modal
            title="Aplicar filtros"
            open={visible}
            okButtonProps={{
                disabled: values === initialValues,
            }}
            onCancel={() => onClose()}
            onOk={() => { onFilter(values); onClose() }}
            okText="Filtrar"
            footer={(_, { OkBtn, CancelBtn }) => (
                <>
                    <Button disabled={values === initialValues} className="btn-remove" icon={<FilterAltOff fontSize="small" />} onClick={clearFilter}>Limpar filtros</Button>
                    <CancelBtn />
                    <OkBtn />
                </>
            )}
        >
            <Form
                form={form}
                style={{ padding: 20 }}
                onValuesChange={(_, values) => setValues(values)}
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={initialValues}
                colon={false}
                size="large"
            >
                <Form.Item label="Nome" name="name">
                    <Input placeholder="Nome do produto" />
                </Form.Item>
                <Form.Item label={"Categoria"} name="category">
                    <Select placeholder="Selecione as categorias" mode="multiple">
                        {categories?.map((category) => (
                            <Select.Option key={category.id} value={category.name}>
                                {category.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={"Preço"} name="price" >
                    <Slider range={{ draggableTrack: true }} max={1000} step={10} marks={
                        {
                            0: "R$0,00",
                            500: "R$500,00",
                            1000: "R$1000"
                        }
                    }
                        tooltip={
                            {
                                formatter: (value) => toBRL(value),
                            }
                        } />
                </Form.Item>
            </Form>
        </Modal >
    );
}
export default FilterModal;

const initialValues = {
    name: "",
    category: [],
    price: [],
};
