import { useState } from "react";
import { useQuery } from "react-query";
import { List, Card, Avatar, Button } from "antd";
import { FilterAltOutlined, FilterAlt, ShoppingBagOutlined, EditOutlined, ViewListOutlined, GridViewOutlined } from "@mui/icons-material";
import api from "api";
import toBRL from "helpers/toBRL";
import NoImage from "assets/images/no-image.png";
import FilterModal from "./filterModal";
import './productStyle.css'
import EditModal from "./editModal";
import { parseQuantity } from "helpers/decimalHelper";

const Products = () => {
    // Estado local para a página atual
    const [page, setPage] = useState(1);
    const [mode, setMode] = useState("grid");
    const [filter, setFilter] = useState({});
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [editProps, setEditProps] = useState({ isEditing: false, productToEdit: {} });

    // Função para buscar produtos
    const getProducts = async (currentPage) => {
        const parsedFilter = Object.entries(filter).map(([key, value]) => {
            return `&${key}=${value}`;
        });
        const companyId = sessionStorage.getItem("companyId");
        const response = await api.get(
            `/products?company_id=${companyId}&page=${currentPage}${parsedFilter.join("")}`,
        );
        return response.data;
    };

    // UseQuery para buscar produtos com paginação
    const {
        data: productsData,
        isLoading,
        isError,
        isFetching,
        refetch,
    } = useQuery(["products", page, filter], () => getProducts(page), {
        initialData: { data: [], total_entries: 0 },
    });

    const handleEditProduct = (product) => {
        setEditProps({ isEditing: true, productToEdit: product });
    }

    return (
        <>
            <div style={{ padding: 20 }}>
                <div className="header">
                    <h1>Todos os produtos</h1>
                    <div>
                        {
                            Object.keys(filter).length > 0 ?
                                <Button className="footer-btn" icon={<FilterAlt className="view-icon colored" />} onClick={() => setIsFilterOpen(true)} >Filtrar</Button> :
                                <Button className="footer-btn" icon={<FilterAltOutlined className="view-icon colored" />} onClick={() => setIsFilterOpen(true)} >Filtrar</Button>
                        }
                        <Button className="footer-btn" icon={
                            mode === "grid" ?
                                <ViewListOutlined className="view-icon" /> :
                                <GridViewOutlined className="view-icon" />
                        } onClick={() => setMode(mode === "grid" ? "list" : "grid")} >
                            Visualização em {mode === "list" ? "grade" : "lista"}
                        </Button>
                    </div>
                </div>
                {isError ? (
                    <div>Erro ao buscar produtos.</div>
                ) : (
                    <List
                        pagination={{
                            position: "bottom",
                            align: "center",
                            showTotal: (total) => `Total: ${total} registros`,
                            total: productsData.total_entries,
                            onChange: (newPage) => setPage(newPage),
                        }}
                        grid={mode === "grid" ? { gutter: 16, column: 5 } : undefined}
                        scroll={{
                            y: 400,
                        }}
                        style={{ fontSize: 14 }}
                        dataSource={productsData.data}
                        renderItem={(item) => (
                            <List.Item >
                                <Card
                                    bordered
                                    className={`${mode} render-item`}
                                    cover={
                                        <img
                                            className="product-image"
                                            style={{ objectFit: "cover" }}
                                            alt={item.name}
                                            src={item.image_path ? "https://" + item.image_path : NoImage}
                                        />
                                    }

                                    actions={[
                                        <Button
                                            className="footer-btn"
                                            icon={<EditOutlined />}
                                            onClick={() => handleEditProduct(item)}
                                        >
                                            Editar
                                        </Button>,
                                        <Button
                                            type="primary"
                                            className="footer-btn"
                                            icon={<ShoppingBagOutlined />}
                                        >
                                            Vender
                                        </Button>,
                                    ]}

                                >
                                    <Card.Meta
                                        description={
                                            <div style={{ minHeight: 130 }}>
                                                Preço: <b>{toBRL(item.sale_price)}</b>
                                                <br /> Estoque: <b>{parseQuantity(item.quantity, null, null, item.unit_of_measurement || "unit", true)}</b>
                                                <br /> Código: <b>{item.bar_code}</b>
                                                <br /> Categoria:{" "}
                                                <b>{item.category?.name || "Indefinida"}</b>
                                                <br /> Marca: <b>{item.brand || "Indefinida"}</b>
                                            </div>
                                        }
                                        title={item.name}
                                        avatar={<Avatar gap={4}>{item.name[0]}</Avatar>}
                                    />
                                </Card>
                            </List.Item>
                        )}
                        loading={isLoading || isFetching}
                    />
                )}
            </div>
            <FilterModal
                visible={isFilterOpen}
                onClose={() => setIsFilterOpen(false)}
                onFilter={(newFilter) => {
                    setFilter(newFilter);
                    setPage(1);
                }}
                clear={() => setFilter({})}
            />
            <EditModal
                visible={editProps.isEditing}
                onClose={
                    (changed = false) => {
                        setEditProps({ isEditing: false, productToEdit: {} });
                        changed && refetch();
                    }
                }
                product={editProps.productToEdit} />
        </>
    );
};

export default Products;
