import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Descriptions, Empty, Input, List, Modal, Typography } from 'antd';
import api from 'api';
import { useState } from 'react';
import { useQuery } from 'react-query';
const CustomerSelectorModal = ({ open, onClose, onSelect }) => {

    const [search, setSearch] = useState("")
    const minLengthToSearch = 3;
    const getCustomers = (search) => async () => {
        const companyId = sessionStorage.getItem('companyId');
        const { data: { data } } = await api.get(`/customers?name=${search}&company_id=${companyId}`)
        return data;
    }

    const { data: customers, isFetching } = useQuery(['customers', search], getCustomers(search), { enabled: search.length >= minLengthToSearch })

    return (
        <Modal onCancel={() => { onClose(); setSearch('') }} title="Escolha um cliente"
            footer={null}
            open={open}>
            <Input.Search value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Digite o nome do cliente" />
            {
                search.length >= minLengthToSearch ? <List
                    style={{ maxHeight: "50vh", overflow: "auto", marginBlock: 10 }}
                    itemLayout="horizontal"
                    dataSource={customers}
                    loading={isFetching}
                    renderItem={(item) => (
                        <List.Item style={{ marginRight: 5 }} actions={[<Button type='primary' onClick={() => { onSelect(item); onClose(); setSearch('') }}>Selecionar</Button>]}
                        >
                            <List.Item.Meta
                                avatar={<Avatar src={item.image_path ? "https://" + item.image_path : <UserOutlined />} />}
                                title={item.name}
                                description={
                                    <Descriptions colon={false} size='small' column={1}>
                                        <Descriptions.Item label="CPF">
                                            <Typography.Text type={!item.document ? 'warning' : 'default'} ellipsis>{item.document || 'CPF não informado'}</Typography.Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Endereço">
                                            <Typography.Text type={!item.street ? 'warning' : 'default'} ellipsis>{item.street || 'Rua não informada'}, {item.number || 'S/N'}</Typography.Text>
                                        </Descriptions.Item>
                                    </Descriptions>
                                }
                            />
                        </List.Item>
                    )}
                /> :
                    <Empty style={{ paddingBlock: 20 }} description={`Digite pelo menos ${minLengthToSearch} caracteres para buscar`} />
            }
        </Modal>
    )
};
export default CustomerSelectorModal;