import { DeleteFilled } from "@ant-design/icons";
import {
    Button,
    Descriptions,
    Divider,
    Image,
    InputNumber,
    Space
} from "antd";
import NoImage from "assets/images/no-image.png";
import toBRL from "helpers/toBRL";
import styles from "../styles.module.css";
import { parseQuantity } from "helpers/decimalHelper";
const CartList = ({addedProducts, handleQuantityChange, setAddedProducts}) => {
    return (
        <div className={styles.products}>
            {addedProducts.map((product) => (
                <Space
                    split={<Divider type="vertical" />}
                    size="small"
                    className={styles.productSpace}>
                    <Image
                        style={{ borderRadius: 5, objectFit: 'cover' }}
                        width={"6rem"}
                        height={"6rem"}
                        preview={!!product.image_path}
                        fallback={NoImage}
                        src={product.image_path && "https://" + product.image_path || NoImage}
                    />
                    <Descriptions contentStyle={{ overflow: 'hidden' }} className={styles.productInfo} title={product.name} column={2}>
                        <Descriptions.Item label="Marca">{product.brand}</Descriptions.Item>
                        <Descriptions.Item label="Preço">{toBRL(product.sale_price)}</Descriptions.Item>
                        <Descriptions.Item label="Código">{product.bar_code}</Descriptions.Item>
                        <Descriptions.Item label="Em estoque">{parseQuantity(product.quantity, null, null, product.unit_of_measurement || "unit")}</Descriptions.Item>
                    </Descriptions>
                    <div className={styles.productQuantity}>
                        <span>Quantidade</span>
                        <InputNumber
                            placeholder="Quantidade"
                            size="middle"
                            value={product.product_quantity}
                            controls={false}
                            title="Quantidade"
                            onChange={(value) => handleQuantityChange(value, product)}
                            style={{ width: "100%" }}
                        />
                        <Button className={styles.fixedDeleteIcon} type="primary" danger icon={<DeleteFilled />} onClick={() => {
                            setAddedProducts(addedProducts.filter((addedProduct) => addedProduct.id !== product.id))
                        }} >Remover</Button>
                    </div>
                </Space>
            ))}
        </div>
    )
}
export default CartList;