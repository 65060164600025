import {
  AppstoreAddOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  PieChartOutlined,
  ProductFilled,
  UserAddOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import {
  AccountCircle,
  PeopleAlt,
  PlaylistAdd,
  PointOfSale,
  ReceiptLong,
  Search as SearchIcon,
  WorkHistory
} from "@mui/icons-material";
import { AppBar, Toolbar } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { Menu } from "antd";
import Icon from "assets/images/Icon.svg";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DarkModeSwitch } from "react-toggle-dark-mode"; // Assuming this is the correct import
import { ThemeContext } from "themes/themeContext";
import NewProduct from "./products/editModal";
import NewCustomer from "./customers/editModal";

const getItems = (isExpanded) => [
  {
    label: null,
    key: "0",
    icon: <img alt="icon" height={40} src={Icon} />,
    disabled: true,
    style: {
      cursor: "default",
      marginBottom: 20,
      display: "flex",
      flexDirection: "column",
      paddingInline: 0,
      marginInline: 0,
      alignItems: "center",
      justifyContent: "center",
    },
  },
  {
    label: "Dashboard",
    key: "app",
    icon: <PieChartOutlined />,
  },
  {
    label: "Frente de caixa",
    key: "app/pos",
    icon: <PointOfSale />,
  },
  {
    label: "Produtos",
    key: "app/products",
    icon: <ProductFilled />,
  },
  {
    label: "Clientes",
    key: "app/customers",
    icon: <PeopleAlt />,
  },
  {
    label: "Contas a receber",
    key: "app/receivables",
    icon: <ReceiptLong />
  },
  {
    label: "Vendedores",
    key: "app/employees",
    icon: <AccountCircle />,
  },
  {
    label: "Histórico de vendas",
    key: "app/sales-history",
    icon: <WorkHistory />,
  },
  {
    label: "Cadastros",
    key: "app/cadastros",
    icon: <PlaylistAdd />,
    type: "item",
    children: [
      {
        label: "Novo produto",
        key: "product",
        icon: <AppstoreAddOutlined />,
        type: "item",
      },
      {
        label: "Novo cliente",
        key: "customer",
        icon: <UserAddOutlined />,
        type: "item",
      },
      {
        label: "Novo vendedor",
        key: "employee",
        icon: <UsergroupAddOutlined />,
        type: "item",
      },
    ],
  },
  {
    label: !isExpanded ? "Expandir" : "Retrair",
    key: "expand",
    icon: !isExpanded ? <DoubleRightOutlined /> : <DoubleLeftOutlined />,
    style: {
      cursor: "pointer",
      position: "absolute",
      bottom: 20,
    },
  },
];

const Layout = ({ children }) => {
  const { toggleTheme, theme } = useContext(ThemeContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [isAddingCustomer, setIsAddingCustomer] = useState(false);
  const { pathname } = useLocation();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.paper, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: isDarkMode ? "#fff" : "#0f0f0f",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: isDarkMode ? "#fff" : "#000",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const handleClick = (key) => {
    switch (key[0]) {
      case "expand":
        setIsExpanded(!isExpanded);
        break;
      case "product":
        setIsAddingProduct(true);
        break;
      case "customer":
        setIsAddingCustomer(true);
        break;
      default:
        navigate(`/${key.reverse().join("/")}`);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          overflow: "hidden",
        }}
      >
        <Menu
          style={{
            minHeight: "100%",
            position: "fixed",
            maxWidth: "20%",
            height: "100%",
            zIndex: 1,
            backgroundColor: isDarkMode ? "#17241f" : "#e3f2ed",
            paddingTop: 20,
            boxShadow: `-2px 2px 7px 1px ${!isDarkMode ? "#17241f" : "#bbe6d8"}`,
          }}
          selectedKeys={[pathname.slice(1)]}
          mode="inline"
          theme={isDarkMode ? "dark" : "light"}
          inlineCollapsed={!isExpanded}
          items={getItems(isExpanded)}
          onClick={({ keyPath }) => handleClick(keyPath)}
        ></Menu>
        <div

          style={{
            width: isExpanded ? "80%" : "100%",
            marginLeft: isExpanded ? "20%" : 80,
            transition: "all 0.1s ease-in-out",
          }}
        >
          <AppBar position="static">
            <Toolbar>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Pesquisar..."
                  inputProps={{ "aria-label": "pesquisa" }}
                />
              </Search>
              <div style={{ position: "absolute", right: 20 }}>
                <DarkModeSwitch
                  sunColor="#edfbf7"
                  moonColor="#a127d8"
                  checked={isDarkMode}
                  onChange={toggleTheme}
                  size={25}
                />
              </div>
            </Toolbar>
          </AppBar>
          <div style={{ marginTop: "2vh" }}>{children}</div>
        </div>
      </div>
      <NewProduct visible={isAddingProduct} onClose={() => setIsAddingProduct(false)} />
      <NewCustomer visible={isAddingCustomer} onClose={() => setIsAddingCustomer(false)} />
    </>
  );
};

export default Layout;
