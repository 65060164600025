export default function applyPhoneMaskOnTyping(input, fallback) {
    let phone = input || fallback
    if (!phone) return
    if (phone.length > 15) return phone.substring(0, 15)
  
    phone = phone.replace(/\D/g, '')
    phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2')
    phone = phone.replace(/(\d)(\d{4})$/, '$1-$2')
  
    return phone
  }
  