import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    DollarOutlined,
    DownloadOutlined,
    InfoCircleOutlined,
    UserOutlined
} from "@ant-design/icons";
import { Event } from "@mui/icons-material";
import {
    App,
    Avatar,
    Badge,
    Button,
    Collapse,
    Divider,
    Drawer,
    InputNumber,
    List,
    Modal,
    Progress,
    Radio,
    Space,
    Tag,
    Typography,
    theme
} from "antd";
import api from "api";
import { setNativeValue } from "helpers/decimalHelper";
import toBRL from "helpers/toBRL";
import toCurrencyInput from "helpers/toCurrencyInput";
import html2canvas from "html2canvas";
import { DateTime } from "luxon";
import { useState, useRef } from "react";
import { useMutation } from "react-query";
import PaymentModal from "./paymentModal";


const Details = ({ open, onClose, receivable, loading }) => {
    const { message } = App.useApp();
    const { token } = theme.useToken();
    const receiptRef = useRef();

    const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [paymentProps, setPaymentProps] = useState({ open: false, receivableId: null, paymentMethod: null, value: null });

    const downloadReceipt = async (el, name) => {
        setIsDownloading(true);
        setTimeout(async () => {
            try {
                const canvas = await html2canvas(el);
                const url = canvas.toDataURL("image/png");
                const a = document.createElement("a");
                a.href = url;
                a.download = name;
                a.click();
                a.remove();
            } catch (error) {
                console.log(error);
                message.error("Erro ao baixar comprovante. Tente novamente mais tarde.");
            } finally {
                setIsDownloading(false);
            }
        }, 200);
    }
    const handleReceive = async (receivableId, value, paymentMethod) => {
        const companyId = sessionStorage.getItem("companyId");
        const payload = {
            receivable_id: receivableId,
            amount: value,
            payment_method: paymentMethod,
            company_id: companyId,
        }
        console.log(payload)
        await api.post('/billings/payment', payload);
    }

    const newPaymentMutation = useMutation({
        mutationFn: ({ receivableId, value, paymentMethod }) => handleReceive(receivableId, value, paymentMethod),
        onSuccess: () => {
            setPaymentProps({ open: false, receivableId: null, paymentMethod: null, value: null });
            message.success("Pagamento registrado com sucesso");
            onClose(true);
        },
        onError: (error) => {
            message.error("Erro ao registrar pagamento. Tente novamente mais tarde.");
            console.log(error)
        }
    });
    const onChangePaymentProps = (key, value) => setPaymentProps({ ...paymentProps, [key]: value });

    const DrawerContent = () => {
        if (!receivable) return <></>;
        return (
            <>
                <Space size="small" style={{ width: '100%', marginBottom: 30 }} direction="vertical" align="center">
                    <Avatar size={60} src={receivable?.image_path && "https://" + receivable?.image_path} icon={<UserOutlined />} />
                    <Space size="small" align="center" direction="vertical" >
                        <Typography.Title style={{ marginBlock: 5 }} level={4}>{receivable?.name}</Typography.Title>
                        <Typography.Text>{receivable?.document}</Typography.Text>
                    </Space>
                </Space>
                <Divider>Compras</Divider>
                <Collapse

                    bordered
                    items={receivable?.sales?.map((sale) => {
                        return {
                            key: sale.id,
                            label: <div>
                                <Space key={sale.id} split size="small" align="center">
                                    <Typography.Text strong> Compra de {toBRL(sale.total_amount)}</Typography.Text>
                                    <Tag icon={<InfoCircleOutlined />} color="geekblue-inverse">
                                        {isDownloading ? DateTime.fromISO(sale.updated_at).toLocaleString({ month: 'long', day: 'numeric' }) :
                                            DateTime.fromISO(sale.updated_at).toRelative({ base: DateTime.local() })}
                                    </Tag>
                                </Space>
                                <Progress percent={(sale.receivables.filter((rec) => rec.status === 'paid').length / sale.receivables.length * 100).toFixed()} />
                            </div>,
                            children: <RenderTransactions token={token} sale={sale} paymentProps={paymentProps} setPaymentProps={setPaymentProps} />
                        }
                    })}
                    defaultActiveKey={isDownloading && receivable?.sales?.map((sale) => sale.id)}
                />
            </>
        )
    }


    return (
        <>
            <Drawer
                title="Detalhes da conta"
                open={open}
                style={{ zIndex: 9998 }}
                width={'35%'}
                loading={loading}
                onClose={() => onClose()}
                extra={
                    <Space>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={() => downloadReceipt(receiptRef.current, `comprovante_${receivable?.name}.png`)}
                        >
                            Baixar
                        </Button>
                    </Space>
                }
            >
                {!loading && <div ref={receiptRef}><DrawerContent /></div>}
                <PaymentModal receivableId={receivable?.id} open={isPaymentModalOpen} onClose={() => setPaymentModalOpen(false)} />
            </Drawer>
            <Modal
                title="Registrar pagamento"
                open={paymentProps.open}
                onCancel={() => onChangePaymentProps("open", false)}
                onOk={() => newPaymentMutation.mutate(paymentProps)}
                confirmLoading={newPaymentMutation.isLoading}
            >
                <Divider>Valor do pagamento</Divider>
                <InputNumber
                    id="paymentValue"
                    style={{ width: '100%' }}
                    variant="filled"
                    onChange={(value) => value !== paymentProps.defaultValue && onChangePaymentProps("value", value / 100)}
                    prefix="R$"
                    controls={false}
                    formatter={(value, { input }) => toCurrencyInput(input, value)}
                />
                <Divider>Forma de pagamento</Divider>
                <Radio.Group style={{ width: '100%' }} onChange={(event) => onChangePaymentProps("paymentMethod", event.target.value)} value={paymentProps.paymentMethod} defaultValue="in_cash" buttonStyle="solid">
                    <Radio.Button value="in_cash">Em Dinheiro</Radio.Button>
                    <Radio.Button value="credit_card">Cartão de crédito</Radio.Button>
                    <Radio.Button value="debit_card">Cartão de débito</Radio.Button>
                    <Radio.Button value="pix">Pix</Radio.Button>
                </Radio.Group>

            </Modal>
        </>
    );
}

export default Details;

const RenderTransactions = ({ sale, setPaymentProps, paymentProps, token }) => {
    console.log(sale)
    const items = sale?.receivables?.map((rec) => {

        return {
            key: rec.id,
            title: <div>{rec.installment_number}ª - {toBRL(rec.amount)}
                <Badge
                    style={{ paddingInline: 10 }}
                    count={rec.status === 'paid' ?
                        <CheckCircleOutlined style={{ color: token.colorPrimary }} /> :
                        <ClockCircleOutlined style={{ color: token.colorWarning }} />
                    }
                />
            </div>,
            avatar: <Event />,
            description: <div>
                <span>{`Restam ${toBRL(rec.remaining_amount, true)}`}</span>
                <div>{`Vence no dia ${DateTime.fromISO(rec.due_date).toLocaleString({ month: 'long', day: 'numeric' })}`}</div>
                {rec.status !== 'paid' && rec.remaining_amount !== rec.amount &&
                    <Progress percent={100 - (100 / ((parseFloat(rec.amount) / parseFloat(rec.remaining_amount)))).toFixed()} />
                }
            </div>,
            isPaid: rec.status === 'paid',
            remaining_amount: rec.remaining_amount,
        };
    })

    return <List key={sale.id} dataSource={items} renderItem={
        (item) =>
            <List.Item extra={[
                <Button size="small" disabled={item.isPaid} icon={<DollarOutlined />} onClick={() => {
                    setNativeValue("paymentValue", item.remaining_amount);
                    setPaymentProps({ ...paymentProps, receivableId: item.key, open: true, defaultValue: `${item.remaining_amount}`.replace(/\D/g, '') / 100 });
                }} type="primary">Receber</Button>
            ]
            } >
                <List.Item.Meta
                    {...item}
                />
            </List.Item>

    } />
}