import { QueryClient, QueryClientProvider } from "react-query";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from ".";
import Customers from "./customers";
import Layout from "./layout";
import PosRoutes from "./point-of-sales";
import Products from "./products";
import Receivables from "./receivables";
import RegistersRoutes from "./registers";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});
function DashboardRoutes() {
  const hasToken = ![null, "", undefined].includes(
    sessionStorage.getItem("apiToken"),
  );

  return hasToken ? (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <Routes>
          <Route index element={<Dashboard hasToken={hasToken} />} />
          <Route path="products" element={<Products />} />
          <Route path="customers" element={<Customers />} />
          <Route path="cadastros/*" element={<RegistersRoutes />} />
          <Route path="pos/*" element={<PosRoutes />} />
          <Route path="receivables/*" element={<Receivables />} />
          <Route path="*" element={<Navigate to="/app" />} />
        </Routes>
      </Layout>
    </QueryClientProvider>
  ) : (
    <Navigate to={"/login"} />
  );
}

export default DashboardRoutes;
