import { Button, Result } from "antd";
import NoContentIcon from "components/noContentIcon";
const NotFound = () => {
  return <Result
    title="página não encontrada"
    icon={<NoContentIcon width={250} height={250} />}
    subTitle="Desculpe, a página que você visitou não existe."
    extra={<Button type="primary" onClick={() => location.replace('/app')}>Voltar para a página inicial</Button>}
  />
};
export default NotFound;
