import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { App, Button, Divider, Drawer, Form, Input, InputNumber, Popconfirm, Select, Space } from "antd";
import api from "api";
import { parseQuantity, reverseQuantity, setNativeValue } from "helpers/decimalHelper";
import ImageUpload from "helpers/imageUpload";
import toBRL from "helpers/toBRL";
import toCurrencyInput from "helpers/toCurrencyInput";
import { useEffect, useRef } from "react";
import Barcode from "react-barcode";
import { useMutation, useQuery } from "react-query";

const EditModal = ({ product, visible, onClose }) => {
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const newCategoryRef = useRef();
    const mode = product ? "edit" : "create";

    const newCategoryMutation = useMutation({
        mutationFn: () => createCategory(),
        onSuccess: (category) => {
            refetchCategories();
            form.setFieldsValue({ category_id: category.id });
            setNativeValue("newCategoryId", "");
            message.success("Categoria criada com sucesso")
        },
        onError: () => message.error("Erro ao criar categoria. Tente novamente mais tarde."),
    })

    const createCategory = async () => {
        const newCategory = newCategoryRef.current.input.value;
        if (!newCategory) return;
        const companyId = sessionStorage.getItem("companyId");
        const { data } = await api.post('/categories', { name: newCategory, company_id: companyId });
        return data;
    }

    const getCategories = async () => {
        const companyId = sessionStorage.getItem("companyId");
        const { data: response } = await api.get(`/categories?company_id=${companyId}`);
        return response.data;

    }
    const { data: categories, refetch: refetchCategories } = useQuery("categories", getCategories, { initialData: [], enabled: visible });


    const updateProduct = async () => {
        const companyId = sessionStorage.getItem("companyId");
        const editedProduct = form.getFieldsValue(true);
        const payload = {
            ...editedProduct,
            sale_price: `${editedProduct.sale_price}`.replace(/\D/g, '') / 100,
            purchase_price: `${editedProduct.purchase_price}`.replace(/\D/g, '') / 100,
            quantity_limit: reverseQuantity(editedProduct.quantity_limit, editedProduct.unit_of_measurement),
            quantity: reverseQuantity(editedProduct.quantity, editedProduct.unit_of_measurement),
            image_path: editedProduct.image_path?.split(".com/")[1] ?? null,
            image: editedProduct.image?.split(',')[1],
            company_id: companyId
        }

        await api.post('/products', payload)
    }

    const deleteProduct = async () => {
        const companyId = sessionStorage.getItem("companyId");
        await api.delete(`/products/${product?.id}?company_id=${companyId}`);
        onClose();
    }

    const updateProductMutation = useMutation({
        mutationFn: () => updateProduct(),
        onSuccess: () => {
            message.success("Produto editado com sucesso");
            form.resetFields();
            onClose(true);
        },
        onError: (error) => {
            message.error("Erro ao editar produto. Tente novamente mais tarde.");
            console.log(error)
        }
    })
    const deleteProductMutation = useMutation({
        mutationFn: () => deleteProduct(),
        onSuccess: () => {
            message.success("Produto excluído com sucesso");
            form.resetFields();
            onClose(true);
        },
        onError: (error) => {
            message.error("Erro ao excluir produto. Tente novamente mais tarde.");
            console.log(error)
        }
    })


    const parseValues = (product) => {
        form.setFieldsValue({
            ...product,
            sale_price: toBRL(product?.sale_price, true),
            purchase_price: toBRL(product?.purchase_price, true),
            image: null,
            unit_of_measurement: parseUnitOfMeasurement(product?.unit_of_measurement),
            quantity: parseQuantity(product?.quantity || 0, undefined, undefined, product?.unit_of_measurement),
            quantity_limit: parseQuantity(product?.quantity_limit || 0, undefined, undefined, product?.unit_of_measurement),
        });

    }

    useEffect(() => {
        visible && mode === "edit" && parseValues(product);
    }, [visible])
    return (
        <Drawer
            title={mode === "edit" ? "Editar produto" : "Novo produto"}
            open={visible}
            width={'40%'}
            onOk={() => updateProductMutation.mutate()}
            loading={updateProductMutation.isLoading || deleteProductMutation.isLoading}
            onClose={() => { form.resetFields(); onClose(); }}
            extra={
                <Space>
                    {mode === "edit" && <Popconfirm
                        title="Tem certeza que deseja excluir este produto?"
                        onConfirm={deleteProductMutation.mutate}
                        okText="Sim"
                        cancelText="Não"
                        description="Esta ação não poderá ser desfeita"
                        okButtonProps={{ danger: true }}
                    >
                        <Button className="btn-remove" icon={<DeleteOutlined />}>Excluir</Button>
                    </Popconfirm>}
                    <Button type="primary" onClick={() => updateProductMutation.mutate()}>Salvar</Button>
                </Space>
            }
        >
            <Form
                className="edit-form"
                form={form}
                style={{ padding: 20, overflowY: "auto", }}
                layout="vertical"
                wrapperCol={{ span: 24 }}
                colon={false}
                size="large"
            >
                <ImageUpload
                    className="image-upload"
                    form={form}
                    field="image_path"
                    onSelect={(image) => form.setFieldValue("image", image)}
                />

                <div className="row">
                    <Form.Item label="Nome" name="name">
                        <Input placeholder="Nome do produto" />
                    </Form.Item>
                    <Form.Item label={"Categoria"} name="category_id">
                        <Select
                            showSearch
                            placeholder="Selecione as categorias"
                            optionFilterProp="children"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider/>
                                    <Space size="small">
                                        <Input
                                            id="newCategoryId"
                                            ref={newCategoryRef}
                                            size="middle"
                                            placeholder="Nova categoria"
                                        />
                                        <Button size="middle" type="primary" icon={<PlusOutlined />} onClick={newCategoryMutation.mutate}>
                                            Criar
                                        </Button>
                                    </Space>
                                </>
                            )}
                            filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                        >
                            {categories?.map((category) => (
                                <Select.Option key={category.id} value={category.id}>
                                    {category.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className="row">
                    <Form.Item label={"Preço de venda"} name="sale_price" >
                        <InputNumber
                            prefix="R$"
                            style={{ width: "100%" }}
                            controls={false}
                            formatter={(value, { input }) => toCurrencyInput(input, value)}
                            placeholder="Preço de venda"
                        />
                    </Form.Item>
                    <Form.Item label={"Preço de compra"} name="purchase_price" >
                        <InputNumber
                            prefix="R$"
                            style={{ width: "100%" }}
                            controls={false}
                            formatter={(value, { input }) => toCurrencyInput(input, value)}
                            placeholder="Preço de compra"
                        />
                    </Form.Item>
                </div>
                <div className="row">
                    <Form.Item label="Marca" name="brand">
                        <Input placeholder="Marca" />
                    </Form.Item>
                    <Form.Item label={"Unidade de medida"} name="unit_of_measurement">
                        <Select defaultValue={"unit"} placeholder="Unidade de medida">
                            <Select.Option value="unit">Unidades</Select.Option>
                            <Select.Option value="kilo">Quilogramas</Select.Option>
                            <Select.Option value="centimeter">Metros</Select.Option>
                            <Select.Option value="liters">Litros</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className="row">
                    <Form.Item label="Estoque atual" name="quantity">
                        <InputNumber controls={false} formatter={(value, { input }) => parseQuantity(value, input, form)} style={{ width: "100%" }} placeholder="Estoque" />
                    </Form.Item>
                    <Form.Item label="Estoque mínimo" name="quantity_limit">
                        <InputNumber controls={false} formatter={(value, { input }) => parseQuantity(value, input, form)} style={{ width: "100%" }} placeholder="Estoque mínimo" />
                    </Form.Item>
                </div>
                <div className="row">
                    <Form.Item label="Código de barras" name="bar_code">
                        <Input placeholder="Código de barras" />
                    </Form.Item>
                    {mode === "edit" && <Barcode displayValue={false} height={50} background="rgba(0,0,0,0)" lineColor="#1a8a66" value={product?.bar_code} />}
                </div>
            </Form>
        </Drawer >
    );
}
export default EditModal;

const parseUnitOfMeasurement = (unit = "unit") => {
    if (["gram", "kilo"].includes(unit)) return "kilo";
    if (["centimeter", "meter"].includes(unit)) return "centimeter";
    return unit;
}
