import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

document.styleSheets[0].insertRule(`::-webkit-scrollbar { width: 8px; }`)
document.styleSheets[0].insertRule(`::-webkit-scrollbar-track { background-color: rgba(0,0,0, 0.5); border-radius: 10px; }`)
document.styleSheets[0].insertRule(`::-webkit-scrollbar-thumb { background-color: rgb(39, 216, 161, 0.7); border-radius: 10px; cursor: pointer;}`)
document.styleSheets[0].insertRule(`::-webkit-scrollbar-thumb:hover { background-color: rgb(39, 216, 161); }`)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
