import React, { createContext, useState, useEffect } from "react";
import MainTheme from "./mainTheme";
import {
  ThemeProvider as ThemeProviderMUI,
  createTheme,
} from "@mui/material/styles";
import { ConfigProvider, theme as antdTheme } from "antd";
import ptBR from "antd/locale/pt_BR";

// Crie o contexto
export const ThemeContext = createContext();

// Crie o provedor do contexto
export const ThemeProvider = ({ children }) => {
  const { defaultAlgorithm, darkAlgorithm } = antdTheme;

  const style = createTheme({
    ...MainTheme,
    pallete: { ...MainTheme.palette, mode: "dark" },
  });
  const cachedTheme = localStorage.getItem("theme") || "dark";

  // Defina o estado do tema
  const [themeStyle, setThemeStyle] = useState(style);
  const [theme, setTheme] = useState(cachedTheme);
  useEffect(() => {
    const styleUE = {
      ...MainTheme,
      palette: { ...MainTheme.palette, mode: theme },
    };
    setThemeStyle(createTheme(styleUE));
    const backgroundColor = theme === "dark" ? "#000" : "#fff";
    document.documentElement.style.backgroundColor = backgroundColor;

    localStorage.setItem("theme", theme);
  }, [theme]);
  // Defina a função para alternar o tema
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  // Forneça o estado e a função de alternância para os componentes filhos
  const getDefaultCustomization = (mode) => {
    return {
      token: {
        colorPrimary: MainTheme.palette.primary[mode],
        borderRadius: 10,
        fontSize: 14,
        fontFamily: "Inter",
        colorLink: MainTheme.palette.primary[mode],
        colorBgContainer: MainTheme.palette.containerBg[mode],
        colorBorderSecondary: MainTheme.palette.colorBorderSecondary[mode],
      },
      components: {
        Menu: {
          fontSize: 16,
          iconSize: 16,
          collapsedIconSize: 20,
        },
        Table: {
          stickyScrollBarBorderRadius: 10,
        }
      },
    };
  };
  return (
    <ThemeProviderMUI theme={themeStyle}>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <ConfigProvider
          theme={{
            algorithm: theme === "dark" ? darkAlgorithm : defaultAlgorithm,
            ...getDefaultCustomization(theme),
          }}
          locale={ptBR}
        >
          {children}
        </ConfigProvider>
      </ThemeContext.Provider>
    </ThemeProviderMUI>
  );
};
