import api from "../api";
const login = async (email, password, onError, loading) => {
  if ([email, password].includes("")) {
    return onError();
  }
  loading(true);
  const payload = {
    email: email,
    password: password,
  };
  try {
    const { data } = await api.post("/accounts/login", payload);
    sessionStorage.setItem("apiToken", `Bearer ${data.jwt}`);
    sessionStorage.setItem("companyId", data.user?.company_id);
    loading(false);
    if (data.application === "billing") {
      return (window.location.href = "/billing");
    }
    return (window.location.href = "/app");
  } catch (error) {
    sessionStorage.setItem("apiToken", "");
    loading(false);
    return onError();
  }
};
export { login };
