
const MainTheme = {
  palette: {
    primary: {
      main: "#1fac80",
      contrastText: "rgba(255,255,255,0.87)",
      light: "#1fac80",
      dark: "#27d8a1",
    },
    secondary: {
      main: "#ec6500",
      light: "#ec6500",
      dark: "#ec6500",
    },
    tertiary: {
      main: "#a127d8",
      light: "#a127d8",
      dark: "#a127d8",
    },
    containerBg: {
      dark: "#0f0f0f",
      light: "#f6faf9",
    },
    colorBorderSecondary: {
      light: "#d9d9d9",
      dark: "#1f1f1f",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  shape: {
    borderRadius: 10,
  },
  spacing: 9,
};
export default MainTheme;
