import { Card } from "antd";
const Dashboard = () => {
  const fakeCompanies = [
    {
      name: "ABC Company",
      address: "123 Main St, New York",
      debtRecovered: 5000,
    },
    {
      name: "XYZ Corporation",
      address: "456 Elm St, Los Angeles",
      debtRecovered: 8000,
    },
    {
      name: "PQR Ltd",
      address: "789 Oak St, Chicago",
      debtRecovered: 3000,
    },
  ];

  return (
    <div className="containerPortifolios">
      <h1>Últimas Carteiras</h1>
      <div className="latestPortifolios">
        {fakeCompanies.map((company, index) => (
          <Card key={index} title={company.name} style={{ width: "20%" }}>
            <p>{company.address}</p>
            <p>{company.debtRecovered}</p>
          </Card>
        ))}
      </div>

      <div className="criticalCustomers"></div>

      <div className="nextToExpire"></div>
    </div>
  );
};
export default Dashboard;
