import { ShoppingCartOutlined } from "@ant-design/icons";
import {
    App,
    Avatar,
    Button,
    Card,
    Divider,
    Empty,
    Input,
    Result,
    Select,
    Skeleton,
    Space,
    Typography
} from "antd";
import api from "api";
import { parseQuantity, reverseQuantity } from "helpers/decimalHelper";
import toBRL from "helpers/toBRL";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import CartList from "./components/cartList";
import Checkout from "./components/checkout";
import styles from "./styles.module.css";

const { Search } = Input;

const PointOfSales = () => {
    const { modal } = App.useApp();
    const barCodeRef = useRef();
    const [addedProducts, setAddedProducts] = useState([]);
    const [nameQuery, setNameQuery] = useState("");
    const [barCode, setBarCode] = useState("");
    const [typedBarCode, setTypedBarCode] = useState("");
    const [isSaleFinished, setIsSaleFinished] = useState([false, 0]);

    const getProducts = async (query, type = "name") => {
        const compayId = sessionStorage.getItem('companyId');
        if (type === "name") {
            const { data: { data } } = await api.get(`/products?company_id=${compayId}&name=${query}`);
            return data;
        }
        else {
            const { data } = await api.get(`/products/${barCode}?company_id=${compayId}`);
            return data;
        }
    }

    const { isFetching: isFetchingByBarCode } = useQuery(
        ['product', barCode],
        () => getProducts(barCode, "bar_code"),
        {
            enabled: barCode.length > 3,
            onSuccess: (data) => {
                handleChange(data.id, data);
                setTypedBarCode("");
                setBarCode("");
                barCode.current?.focus();
            },
            onError: () => {
                modal.error({ title: "Produto não encontrado", content: "Verifique o código de barras e tente novamente." })
                setTypedBarCode("");
                setBarCode("");
                barCode.current?.focus();
            }
        }
    )

    const { data: products, isFetching } = useQuery(
        ['products', nameQuery], () => getProducts(nameQuery),
        {
            initialData: [],
            enabled: nameQuery.length > 2,
        })

    const handleQuantityChange = (value, givenProduct) => {
        let parsedValue = parseQuantity(value, null, null, givenProduct.unit_of_measurement)
        if (reverseQuantity(parsedValue) > reverseQuantity(givenProduct.quantity)) {
            parsedValue = 0;
            modal.error({ title: "Quantidade indisponível", content: "A quantidade informada é maior que a quantidade em estoque." })
        }
        setAddedProducts(
            addedProducts.map(
                (addedProduct) =>
                    addedProduct.id === givenProduct.id ?
                        { ...addedProduct, product_quantity: parsedValue } :
                        addedProduct
            )
        );
    }
    const handleChange = (productId, givenProduct) => {
        const productToBeAdded = givenProduct || products.find((product) => product.id === productId);
        const quantityToAdd = {
            unit: 1,
            centimeter: 0.01,
            kilo: 0.001,
        }[productToBeAdded?.unit_of_measurement] || 1;

        if (addedProducts.some((product) => product.id === productId)) {
            if (productToBeAdded.quantity === 0) {
                modal.error({ title: "Produto sem estoque", content: "Não é possível adicionar um produto sem estoque." })
                setNameQuery("");
                return;
            }
            setAddedProducts(
                addedProducts.map(
                    (addedProduct) => addedProduct.id === productId ?
                        { ...addedProduct, product_quantity: reverseQuantity(addedProduct?.product_quantity || parseQuantity) + quantityToAdd } :
                        addedProduct
                )
            )
            setNameQuery("");
            return;

        }
        setAddedProducts([{ ...productToBeAdded, product_quantity: quantityToAdd }, ...addedProducts]);
        setNameQuery("");
    }

    const NotFoundContent = () => {
        if (nameQuery.length <= 2) {
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Digite ao menos 3 caracteres para buscar" />
        }
        if (isFetching) {
            return <Skeleton active />
        }
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }

    const onFinish = (total) => {
        setAddedProducts([]);
        setIsSaleFinished([true, total]);
    }
    const onError = () => {
        modal.error({ title: "Erro ao finalizar a venda", content: "Tente novamente mais tarde." })
    }

    return (
        <div className={styles.container} >
            <Card className={styles.pdv}>
                <div className={styles.header}>
                    <Search
                        ref={barCodeRef}
                        loading={isFetchingByBarCode}
                        placeholder="Insira o código do produto"
                        allowClear
                        autoFocus
                        disabled={isSaleFinished[0]}
                        enterButton="Adicionar"
                        size="large"
                        onChange={(e) => setTypedBarCode(e.target.value)}
                        value={typedBarCode}
                        onSearch={setBarCode}
                        enterKeyHint="search"
                    />
                    <div className={styles.divider}><Divider>ou</Divider></div>
                    <Select
                        disabled={isSaleFinished[0]}
                        showSearch
                        placeholder="Busque pelo nome"
                        size="large"
                        filterOption={false}
                        value={nameQuery || null}
                        defaultValue={null}
                        notFoundContent={<NotFoundContent />}
                        defaultActiveFirstOption={true}
                        options={products.map((product) => ({ label: product.name, value: product.id, image: product.image_path })) || []}
                        onSearch={setNameQuery}
                        loading={isFetching}
                        onChange={(value) => handleChange(value)}
                        optionRender={(option) => (
                            <Space>
                                {option.data.image && <Avatar src={<img src={"https://" + option.data.image} />} />}
                                <span>{option.label}</span>
                            </Space>
                        )}
                    />
                </div>
                <Divider />
                {addedProducts.length === 0 && !isSaleFinished[0] && <Typography.Title
                    style={{ textAlign: 'center', marginTop: "20vh" }}
                    level={1}
                    type="default">
                    CAIXA LIVRE
                </Typography.Title>}
                {
                    isSaleFinished[0] ? <Result
                        status="success"
                        title="Venda finalizada com sucesso"
                        subTitle={`Total: ${toBRL(isSaleFinished[1])}`}
                        extra={[
                            <Button key="new" type="primary" icon={<ShoppingCartOutlined />} onClick={() => setIsSaleFinished([false])}>Nova venda</Button>
                        ]}
                    /> :
                        <CartList addedProducts={addedProducts} setAddedProducts={setAddedProducts} handleQuantityChange={handleQuantityChange} />
                }
            </Card>
            <Checkout onError={onError} onFinish={onFinish} addedProducts={addedProducts} />
        </div>
    );
};

export default PointOfSales;