import { Paper, TextField, Backdrop, CircularProgress } from "@mui/material";
import { useState } from "react";
import { App, Button } from "antd";
import "./Login.css";
import Icon from "assets/images/avant-icon.png";
import IconDark from "assets/images/avant-icon-dark.png";
import { login } from "helpers/auth";
const Login = () => {
  const { message } = App.useApp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isDarkMode = () => localStorage.getItem("theme") === "dark";
  const handleLogin = () => {
    login(
      email,
      password,
      () => message.error("Email ou senha incorretos", 2),
      (loading) => setIsLoading(loading),
    );
  };

  return (
    <div className="backdrop">
      <Paper className="container-login" elevation={8}>
        <img
          alt="avant icon"
          className="icon"
          src={isDarkMode() ? IconDark : Icon}
        />
        <div className="login-form">
          <TextField
            onKeyDown={(e) => e.key === "Enter" && handleLogin()}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
            label="E-mail"
            variant="standard"
          />
          <TextField
            onKeyDown={(e) => e.key === "Enter" && handleLogin()}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className="input"
            label="Senha"
            variant="standard"
          />
        </div>
        <Button
          type="primary"
          onClick={handleLogin}
          className="btn-submit"
          variant="contained"
        >
          Entrar
        </Button>
      </Paper>
      <Backdrop
        sx={{
          backgroundColor: "rgba(0,0,0,0.85)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
};
export default Login;
